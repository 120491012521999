<template>
  <div class="pa-4 h-100-p w-100-p">
    <div class="title mb-4">
      <h1>Helps</h1>
    </div>

    <div class="mt-10 d-flex">
      <v-btn class="mr-3" download color="primary" href="Word_Analysis.pdf">
        Download Instruction
      </v-btn>
      <v-btn color="primary" href="Word_Analysis.pdf" target="_blank">
        Show Instruction
      </v-btn>
    </div>

  </div>
</template>

<script>

export default {
  name: "Help",
};
</script>
